import React from "react";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";

const ratio = 680 / 1920;
const data = [
  // {
  //   link: "https://sensationsprint.tw/-INFOLI日常摺理-挖貝集資問卷",
  //   src: `/images/landing-event-desktop.jpg`,
  //   mobileSrc: `/images/landing-event-mobile.jpg`,
  // },
  {
    link: "/works",
    src: `/images/period-banner-1.webp`,
    mobileSrc: `/images/period-banner-mob-1.webp`,
    alt: "Work 印物",
  },
  {
    link: "/products/?tab=客製化專區",
    src: `/images/period-banner-2.webp`,
    mobileSrc: `/images/period-banner-mob-2.webp`,
    alt: "客製化專區",
  },
  {
    link: "https://www.yottau.com.tw/teacher/1688",
    src: `/images/landing-subscribe-desktop.jpg`,
    mobileSrc: `/images/landing-subscribe-mobile.jpg`,
    alt: "跨越印刷銀河系",
  },
];

export default function PeriodCarousel(props) {
  const { sources = data } = props;
  const { rwd, dimension } = useDimension();

  if (data.length === 1) {
    return (
      <Link href={data[0].link} target="_blank">
        <img
          src={rwd === "mobile" ? data[0].mobileSrc : data[0].src}
          alt={data[0].alt}
        />
      </Link>
    );
  }

  return (
    <Carousel
      width={"100%"}
      // height={"auto"}
      //initialSlideHeight={680}
      // adaptiveHeight={true}
      renderBottomCenterControls={() => false}
      // renderCenterLeftControls={this._renderLeftIcon}
      // renderCenterRightControls={this._renderRightIcon}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomRightControls={props => <Indicator {...props} />}
      // slideIndex={slideIndex}
      // afterSlide={slideIndex => this.setState({ slideIndex })}
      autoplay={true}
      pauseOnHover={true}
      wrapAround={true}
    >
      {sources.map((s, idx) => (
        <Link key={idx} href={s.link} target="_blank">
          <img src={rwd === "mobile" ? s.mobileSrc : s.src} alt={s.alt || ""} />
        </Link>
      ))}
    </Carousel>
  );
}

const Link = styled.a`
  display: block;
  /* padding-top: ${ratio}%;
  overflow: hidden; */
  /* width: 100%; */

  & > img {
    width: 100%;
    height: auto;
  }
`;

function Indicator(props) {
  let { currentSlide, goToSlide, slideCount } = props;
  let indexes = Array.from(Array(slideCount).keys());
  return (
    <IndicatorStyle.Wrapper>
      {indexes.map(index => (
        <IndicatorStyle.Dot
          key={index}
          selected={index === currentSlide}
          onClick={() => goToSlide(index)}
        />
      ))}
    </IndicatorStyle.Wrapper>
  );
}

const IndicatorStyle = {
  Wrapper: styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
  `,
  Dot: styled.div`
    cursor: pointer;
    background-color: ${props =>
      props.selected ? Theme.colors.main : "white"};
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
    box-shadow: 0px 4px 7px -2px #444;
    margin-left: 12px;
  `,
};
