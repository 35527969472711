import React, {
  Fragment,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import useDimension from "../hooks/use-dimension";

const SWIPE_STATE = {
  idle: 0,
  trans: 1,
  swipe: 2,
};

function ItemComp(props) {
  if (props.renderItem) {
    return props.renderItem(props);
  }
  return <SimpleItem {...props}>{JSON.stringify(props.item)}</SimpleItem>;
}

function easeOut(k) {
  return 1 - Math.pow(1 - k, 1.675);
}

function Carousel(props) {
  const {
    data = [1, 2, 3],
    renderItem: _renderItem,
    renderTitle,
    disableSwipe,
    speed = 0.6,
    style = {},
    autoplay = false,
  } = props;
  const [currIdx, setCurrIdx] = useState(0);
  const scrollViewRef = useRef();
  const swipeState = useRef(SWIPE_STATE.idle);
  const swipeOffset = useRef(null);
  const { dimension } = useDimension();
  const [size, setSize] = useState({ width: "100%", height: "100%" });

  useLayoutEffect(() => {
    const elem = document.getElementById("rev-carousel");
    const { width, height } = elem.getBoundingClientRect();
    setSize({ width, height });
  }, [dimension]);

  useEffect(() => {
    // autoplay
    if (autoplay) {
      let timer = setInterval(() => {
        goToIndex(currIdx === data.length - 1 ? 0 : currIdx + 1);
      }, 4000);

      return () => clearInterval(timer);
    }
  }, [autoplay, currIdx, data]);

  const goToIndex = useCallback(
    idx => {
      if (scrollViewRef.current) {
        if (idx < 0) {
          idx = 0;
        } else if (idx >= data.length) {
          idx = data.length - 1;
        }

        const curr = scrollViewRef.current.scrollLeft;
        const target = idx * size.width;
        const steps = speed * 60; // steps = ns * 60
        let stepCnt = 0;

        function updateScrollLeft() {
          stepCnt++;
          const diff =
            (easeOut(stepCnt / steps) - easeOut((stepCnt - 1) / steps)) *
            (target - curr); // ease-out
          // const diff = (target - curr) / steps; // linear

          if (stepCnt === steps) {
            scrollViewRef.current.scrollLeft = target;
            setCurrIdx(idx);
          } else if (stepCnt < steps) {
            scrollViewRef.current.scrollLeft += diff;
            requestAnimationFrame(updateScrollLeft);
          }
        }

        requestAnimationFrame(updateScrollLeft);
      }
    },
    [scrollViewRef.current]
  );

  function finalizeMouseMovement() {
    const idx = Math.round(scrollViewRef.current.scrollLeft / size.width);
    scrollViewRef.current.scrollLeft = idx * size.width;
    setCurrIdx(idx);
  }

  function onMouseDown() {
    swipeState.current = SWIPE_STATE.trans;
  }

  function onMouseUp() {
    swipeState.current = SWIPE_STATE.idle;
    finalizeMouseMovement();
  }

  function onMouseLeave() {
    swipeState.current = SWIPE_STATE.idle;
    finalizeMouseMovement();
  }

  function onMouseMove(e) {
    if (swipeState.current > SWIPE_STATE.idle) {
      if (swipeState.current === SWIPE_STATE.trans) {
        swipeState.current = SWIPE_STATE.swipe;
      } else if (scrollViewRef.current) {
        let diff = e.nativeEvent.clientX - swipeOffset.current;
        scrollViewRef.current.scrollLeft += diff;
        if (scrollViewRef.current.scrollLeft < 0) {
          scrollViewRef.current.scrollLeft = 0;
        }
      }

      swipeOffset.current = e.nativeEvent.clientX;
    }
  }

  const mouseEventHandlers = disableSwipe
    ? {}
    : {
        onMouseDown,
        onMouseUp,
        onMouseMove,
        onMouseLeave,
      };

  return (
    <Fragment>
      <Wrapper {...size} data={data} style={style} id="rev-carousel">
        <div
          className="placeholder"
          ref={scrollViewRef}
          {...mouseEventHandlers}
        >
          <div className="content">
            {data.map((item, idx) => (
              <ItemComp
                key={idx}
                item={item}
                renderItem={_renderItem}
                {...size}
              />
            ))}
          </div>
        </div>
        <Controller {...props} goToIndex={goToIndex} currIdx={currIdx} />
      </Wrapper>
      {renderTitle
        ? renderTitle({
            data,
            currIdx,
            goToIndex,
          })
        : null}
    </Fragment>
  );
}

function Controller(props) {
  const {
    data,
    renderPrev: _renderPrev,
    renderNext: _renderNext,
    renderDots: _renderDots,
    goToIndex,
    currIdx,
  } = props;

  function goToNext() {
    goToIndex(currIdx + 1);
  }

  function goToPrev() {
    goToIndex(currIdx - 1);
  }

  function PrevComp(props) {
    if (_renderPrev) {
      return _renderPrev(props);
    } else if (_renderPrev === null) {
      return null;
    }
    return <SimplePrev onClick={goToPrev} {...props}>{`<`}</SimplePrev>;
  }

  function NextComp(props) {
    if (_renderNext) {
      return _renderNext(props);
    } else if (_renderNext === null) {
      return null;
    }
    return <SimpleNext onClick={goToNext} {...props}>{`>`}</SimpleNext>;
  }

  function Dots(props) {
    if (_renderDots) {
      return _renderDots(props);
    } else if (_renderDots === null) {
      return null;
    }
    return <SimpleDots>{`${currIdx + 1} / ${data.length}`}</SimpleDots>;
  }

  const commonProps = { currIdx, goToIndex };

  return (
    <>
      <NextComp {...commonProps} />
      <PrevComp {...commonProps} />
      <Dots {...commonProps} />
    </>
  );
}

const Wrapper = styled.div`
  position: relative;

  & > .placeholder {
    width: 100%;
    overflow: hidden;

    & > .content {
      width: ${props => props.width * props.data.length}px;
      width: calc(100% * ${props => props.data.length});
      display: flex;
    }
  }
`;

const SimpleItem = styled.div`
  width: ${props => props.size.width}px;
  height: ${props => props.size.height}px;
  background-color: pink;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SimpleNext = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  right: 0px;
  top: calc(50% - 16px);
  border: 1px solid black;
  background-color: white;
`;

const SimplePrev = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  left: 0px;
  top: calc(50% - 16px);
  border: 1px solid black;
  background-color: white;
`;

const SimpleDots = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
`;

export default Carousel;
